import React from "react";

const Index = () => {
  return (
    <section className="our-team-sec section-space">
      <div className="container">
        <div className="section-head text-center mb-5">
          <h2>Our Team</h2>
        </div>
        <div className="row row-cols-4 our-team-rows">
          <div className="cols">
            <div className="our-team-content">
              <figure>
                <img src="images/our-team-img-1.png" />
              </figure>
              <figcaption>
                <h3>Tanya Johnson</h3>
                <span>Business head</span>
              </figcaption>
            </div>
          </div>
          <div className="cols">
            <div className="our-team-content">
              <figure>
                <img src="images/our-team-img-2.png" />
              </figure>
              <figcaption>
                <h3>Walter Smith</h3>
                <span>Blockchain developer</span>
              </figcaption>
            </div>
          </div>
          <div className="cols">
            <div className="our-team-content">
              <figure>
                <img src="images/our-team-img-3.png" />
              </figure>
              <figcaption>
                <h3>Malissa Hite</h3>
                <span>UI designer</span>
              </figcaption>
            </div>
          </div>
          <div className="cols">
            <div className="our-team-content">
              <figure>
                <img src="images/our-team-img-4.png" />
              </figure>
              <figcaption>
                <h3>LissaWatson</h3>
                <span>Company CEO</span>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
