import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import { toast } from "react-toastify";
const Index = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(25).required("First name is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      // subject: Yup.string().max(25).required("Last name is required"),
      message: Yup.string().max(500).required("Message number is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        console.log(values);
        const { data } = await POST(apis.CREATE_CONTACT_APP, null, values, 0);
        helpers.resetForm();
        if (data.status) {
          toast.success(data.message);
        } else {
          toast.success(data.message);
        }
      } catch (err) {
        console.log(err);
        // toast.error(error.response.data.message || error.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <section id="contact" className="contact-us-sec section-space">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <figure>
              <img src="images/get-touch-img.png" alt="img" />
            </figure>
          </div>
          <div className="col-md-6">
            <div className="section-head  mb-4">
              <small>Contact us</small>
              <h2>Get in touch with us</h2>
            </div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="form-group mb-4">
                <div className="input-bg">
                  <label className="form-label">Name</label>
                  <input
                    error={!!(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    label="Name"
                    name="name"
                    id="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="form-control"
                    placeholder="Enter your name."
                  />
                </div>
                {formik.touched.name && formik.errors.name && (
                  <p style={{ color: "red" }}>{formik.errors.name}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <div className="input-bg">
                  {" "}
                  <label className="form-label">Email address</label>
                  <input
                    type="text"
                    error={!!(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email"
                    name="email"
                    id="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="form-control"
                    placeholder="Enter your email."
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <p style={{ color: "red" }}>{formik.errors.email}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <div className="input-bg">
                  {" "}
                  <label className="form-label">Subject</label>
                  <input
                    // error={!!(formik.touched.subject && formik.errors.subject)}
                    // helperText={formik.touched.subject && formik.errors.subject}
                    label="Subject"
                    name="subject"
                    id="subject"
                    // onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                    className="form-control"
                    placeholder="Enter your subject."
                  />
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="input-bg">
                  {" "}
                  <label className="form-label">Messages</label>
                  <textarea
                    className="form-control min-h"
                    style={{ border: "none", background: "transparent" }}
                    error={!!(formik.touched.message && formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                    label="Message"
                    name="message"
                    id="message"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    placeholder="Enter your message."
                  ></textarea>
                </div>
                {formik.touched.message && formik.errors.message && (
                  <p style={{ color: "red" }}>{formik.errors.message}</p>
                )}
              </div>
              <div className="btn-box">
                <button type="submit" className="btn btn-green w-100 min-h-btn">
                  Submit your query
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
