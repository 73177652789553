import React, { useEffect, useState } from "react";
import { GET, PATCH, POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import { getMe, logout } from "../../store/reducers/userSlice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

const Index = () => {
  const { token } = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const dispatch = useDispatch();

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      submit: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(25).required("First name is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        "Phone number is not valid"
      ),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { data } = await PATCH(`${apis.UPDATE_APP_USER}`, token, {
          ...values,
        });
        if (data.status) {
          setUser(data.data);
        } else {
          toast.dismiss();
          toast.error(data.message);
        }
      } catch (error) {
        toast.dismiss();
        toast.error(error.response?.data?.message || error.message);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await GET(`${apis.GET_ME}`, token);
        if (data.status) {
          setUser(data.data);
          dispatch(getMe({ user: data.data }));
          formik.values.firstName = data.data.firstName;
          formik.values.lastName = data.data.lastName;
          formik.values.email = data.data.email;
          formik.values.phoneNumber = data.data.phoneNumber;
        } else {
          toast.dismiss();
          toast.error(data.message);
        }
      } catch (error) {
        toast.dismiss();
        toast.error(error.response?.data?.message || error.message);
      }
    })();
  }, [token, dispatch]);

  //   const updateUser = async () => {

  //   };

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload(false);
    // navigate("/login");
  };

  return (
    <div class="wraper-inner inner-space">
      <section class="my-profile inner-page-space">
        <div class="container">
          <div class="row">
            <div class="col-md-3 my-profile-left">
              <div class="profile-sidebar">
                <div class="profile-head">
                  <figure>
                    <img src="assets/images/user-img.png" />
                  </figure>
                  <h3>
                    {user && user.firstName} {user.lastName}
                  </h3>
                </div>
                <div class="profile-sidebar-body">
                  <ul class="profile-list">
                    <li class="active">
                      <Link to="/profile">
                        <i class="ri-user-fill"></i> My profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i class="ri-lock-fill"></i>Change password
                      </Link>
                    </li>
                    <li>
                      <a onClick={logoutHandler} style={{ cursor: "pointer" }}>
                        <i class="ri-logout-box-line"></i>Log out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-9 ps-5 my-profile-right">
              <div class="my-profile-head mb-5">
                <h4 class="title-profile">My profile</h4>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div class="row">
                  <div class="col-md-6 mb-3 form-group">
                    <label class="form-lable">First name</label>
                    <input
                      type="text"
                      error={
                        !!(formik.touched.firstName && formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      label="First Name"
                      name="firstName"
                      id="firstName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      className="form-control"
                      placeholder="Enter first name"
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <p style={{ color: "red" }}>{formik.errors.firstName}</p>
                    )}
                  </div>
                  <div class="col-md-6 form-group">
                    <label class="form-lable">Last name</label>
                    <input
                      type="text"
                      error={
                        !!(formik.touched.lastName && formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      label="Last Name"
                      name="lastName"
                      id="lastName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      className="form-control"
                      placeholder="Enter last name"
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <label class="form-lable">Email</label>
                    <input
                      type="text"
                      error={!!(formik.touched.email && formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      label="Email"
                      name="email"
                      id="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className="form-control"
                      placeholder="Enter your email"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p style={{ color: "red" }}>{formik.errors.email}</p>
                    )}
                  </div>
                  <div class="col-md-6 form-group">
                    <label class="form-lable">Phone number</label>
                    <input
                      type="text"
                      error={
                        !!(
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        )
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                      label="Phone Number"
                      name="phoneNumber"
                      id="phoneNumber"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      className="form-control"
                      placeholder="Enter your phone number"
                    />
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber && (
                        <p style={{ color: "red" }}>
                          {formik.errors.phoneNumber}
                        </p>
                      )}
                  </div>
                  <div class="col-md-12 mt-3">
                    <button class="btn btn-green">Update profile</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div class="container">
          <div class="row footer-top">
            <div class="col-md-4">
              <figure>
                <img src="assets/images/footer-logo.png" />
              </figure>
            </div>
            <div class="col-md-3">
              <div class="footer-content">
                <h3>Company</h3>
                <ul class="footer-content-list">
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a href="#">FAQ'S</a>
                  </li>
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="footer-content">
                <h3>LEGAL</h3>
                <ul class="footer-content-list">
                  <li>
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                  <li>
                    <a href="#">Cookie policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-2">
              <div class="footer-content">
                <h3>Socials</h3>
                <ul class="socials-content-list">
                  <li>
                    <a href="#">
                      <i class="ri-linkedin-fill"></i>LinkedIn
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="ri-facebook-circle-fill"></i>Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i>
                        <img src="assets/images/tweeter-icon.svg" />
                      </i>
                      (Twitter)
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="ri-instagram-fill"></i>Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-footer text-center">
          <div class="container">
            <p>© 2023 eco2in. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Index;
