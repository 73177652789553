import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function App() {
  const { token } = useSelector((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);
  return <RouterProvider router={router(isLoggedIn)} />;
}

export default App;
