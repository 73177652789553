import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        console.log(values);
        const { data } = await POST(
          apis.FORGOT_PASSWORD_APP_USER,
          null,
          values,
          0
        );
        helpers.resetForm();
        if (data.status) {
          toast.success(data.message);
          navigate(`/verification?email=${values.email}`);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message || err.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="col-md-6 form-left-box">
        <div className="form-box">
          <div className="form-head">
            <h2>Forgot Password</h2>
          </div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label className="form-label">Email address</label>
              <input
                type="text"
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                id="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                className="form-control"
                placeholder="Enter your email."
              />
              {formik.touched.email && formik.errors.email && (
                <p style={{ color: "red" }}>{formik.errors.email}</p>
              )}
            </div>

            <div className="form-submit-btn">
              <button type="submit" className="btn btn-green">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-6 form-right-box">
        <div className="form-des">
          <div className="form-logo">
            <a href="index.html">
              <img src="/images/form-logo.png" alt="logo" />
            </a>
          </div>
          <h2>Welcome to Stable Coin</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
