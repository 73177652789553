import axios from "axios";
import { BASE_URL } from "./apiConstants";

export const POST = async (url, token, body, type) => {
  const headers = {
    contentType: "application/json",
    accept: "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (type) {
    headers.contentType = "multipart/form-data";
    headers.accept = "multipart/form-data";
  }
  console.log(headers);
  return axios.post(`${BASE_URL}${url}`, body, { headers });
};

export const GET = async (url, token) => {
  const headers = {
    contentType: "application/json",
    accept: "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios.get(`${BASE_URL}${url}`, { headers });
};

export const PATCH = async (url, token, body, type) => {
  const headers = {
    contentType: "application/json",
    accept: "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (type) {
    headers.contentType = "multipart/form-data";
    headers.accept = "multipart/form-data";
  }
  console.log(headers);
  return axios.patch(`${BASE_URL}${url}`, body, { headers });
};
export const DELETE = async (url, token) => {
  const headers = {
    contentType: "application/json",
    accept: "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios.delete(`${BASE_URL}${url}`, { headers });
};
