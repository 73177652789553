import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  artifact: null,
  web3: null,
  accounts: [],
  networkID: null,
  contract: null,
};

const ethereumSlice = createSlice({
  name: "ethereum",
  initialState,
  reducers: {
    init: (state, action) => {
      const { artifact, web3, accounts, networkID, contract } = action.payload;
      return {
        ...state,
        artifact,
        web3,
        accounts,
        networkID,
        contract,
      };
    },
  },
});

export const { init } = ethereumSlice.actions;
export default ethereumSlice.reducer;
