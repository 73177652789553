import React, { useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import styled from "@emotion/styled";
import { POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

const Index = () => {
  const [otp, setOtp] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState();
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setEmail(searchParams.get("email"));
  }, [searchParams]);

  const MuiOtpInputStyled = styled(MuiOtpInput)`
    border: "1px solid white";
    color: "white";
  `;

  const reSendOTP = async () => {
    try {
      const { data } = await POST(
        apis.FORGOT_PASSWORD_APP_USER,
        null,
        { email },
        0
      );
      if (data.status) {
        toast.success(data.message);
      } else {
        toast.success(data.message);
      }
    } catch (err) {
      toast.error(err.response.data.message || err.message);
    }
  };

  const verifyOTP = async () => {
    try {
      const { data } = await POST(
        apis.VERIFY_OTP_APP_USER,
        null,
        { email, otp },
        0
      );
      if (data.status) {
        toast.success(data.message);
        navigate(`/reset-password?email=${email}`);
      } else {
        toast.success(data.message);
      }
    } catch (err) {
      toast.error(err.response.data.message || err.message);
    }
  };

  return (
    <>
      <div className="col-md-6 form-left-box">
        <div className="form-box">
          <div className="form-head">
            <h2>Verification</h2>
          </div>
          <form>
            <div className="otp-verification">
              {/* <div className="form-group">
                <input type="text" className="form-control" placeholder="0" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="0" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="0" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="0" />
              </div> */}
              <MuiOtpInputStyled value={otp} onChange={handleChange} />
            </div>
            <div className="form-submit-btn">
              <button
                className="btn btn-green"
                onClick={verifyOTP}
                type="button"
              >
                Verify
              </button>
            </div>
            <div className="form-foot-box">
              <p>
                Didn't receive the code?{" "}
                <div
                  style={{ color: "#abca2f", cursor: "pointer" }}
                  onClick={reSendOTP}
                >
                  Resend
                </div>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-6 form-right-box">
        <div className="form-des">
          <div className="form-logo">
            <a href="index.html">
              <img src="/images/form-logo.png" alt="logo" />
            </a>
          </div>
          <h2>Welcome to Stable Coin</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
