import React from "react";
import RoadmapIndex from "./particles";

const Index = () => {
  return (
    <section id="roadmap" className="roadmap section-space">
      <RoadmapIndex />
      <div className="container">
        <div className="section-head text-center mb-4">
          <h2>Roadmap</h2>
        </div>
        <div className="row row-cols-5 roadmap-rows mt-5">
          <div className="cols">
            <div className="road-map-content text-center">
              <h3>Q4, 2023</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's{" "}
              </p>
            </div>
          </div>
          <div className="cols">
            <div className="road-map-content">
              <h3>Q2, 2024</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's{" "}
              </p>
            </div>
          </div>
          <div className="cols">
            <div className="road-map-content text-center">
              <h3>Q3, 2023</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's{" "}
              </p>
            </div>
          </div>
          <div className="cols">
            <div className="road-map-content text-center">
              <h3>Q1, 2023</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's{" "}
              </p>
            </div>
          </div>
          <div className="cols ">
            <div className="road-map-content text-center">
              <h3>Q3, 2023</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
