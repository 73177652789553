import React, { useEffect, useState } from "react";
import HomeIndex from "./particles";
import Slider from "@mui/material/Slider";
import Timer from "../timer";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BuyModal from "../modal";
import HowToBuyModal from "../howToByModal";

const Index = () => {
  const { token } = useSelector((state) => state.user);
  const [value, setValue] = useState(1);
  const [totalCoin, setTotalCoin] = useState();
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const { accounts, contract, web3 } = useSelector((state) => state.ethereum);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenValue, setTokenValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState("eth");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const CustomSlider = styled(Slider)({});

  useEffect(() => {
    if (window.ethereum) {
      (async () => {
        const balance = await contract?.methods
          ?.balanceOf("0x1082A38E653957dF8D89aF415651ABE29492c001")
          ?.call();
        if (balance) {
          // eslint-disable-next-line no-undef
          setTotalCoin(BigInt(balance) / BigInt(1000000000000000000) + "");
        }
      })();
    }
  }, [accounts, contract]);

  // const handleChangeToggle = (event) => {
  //   setSelectedValue(event.target.value);
  // };

  const buyToken = async () => {
    if (window.ethereum) {
      try {
        if (selectedValue === "crypto") {
          const totalPrice = 0.0002 * value;
          const valueInWei = web3.utils.toWei(totalPrice, "ether");
          const data = await contract?.methods
            ?.sendToken(value)
            .send({ from: accounts[0], value: valueInWei });
          const balance = await contract?.methods
            ?.balanceOf("0x1082A38E653957dF8D89aF415651ABE29492c001")
            ?.call();
          console.log({ balance });
          if (balance) {
            // eslint-disable-next-line no-undef
            setTotalCoin(BigInt(balance) / BigInt(1000000000000000000) + "");
          }
          if (data) {
            toast.success("Token transferred successfully.");
            setOpen(false);
          }
        } else {
          toast.warn("Fiat not available yet.");
          setOpen(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.warn("Please install metamask to buy token.");
    }
  };

  // const setTokenPrice = async () => {
  //   if (window.ethereum) {
  //     try {
  //       const data = await contract?.methods
  //         ?.setTokenPrice(200000000000000)
  //         .call();
  //       if (data) {
  //         toast.success("Token transferred successfully.");
  //         setOpen(false);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   } else {
  //     toast.warn("Please install metamask to buy token.");
  //   }
  // };

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  return (
    <section id="home" className="home-banner">
      <HomeIndex />
      <div style={{ zIndex: 1000000 }} className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="home-content">
              <div className="home-text">
                <h1>
                  A Stable Coin with the greatest upside potential on Earth for
                  Earth
                </h1>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.{" "}
                </p>
                <Link to="/" className="btn btn-green mt-4">
                  Get Started
                </Link>
              </div>
              <div className="home-logos ">
                <h6>Trusted by industry leaders</h6>
                <div className="home-logos-in ">
                  <a href="javascript:;">
                    <img src="images/logo1.svg" alt="image" />
                  </a>
                  <a href="javascript:;">
                    <img src="images/logo2.svg" alt="image" />
                  </a>
                  <a href="javascript:;">
                    <img src="images/logo3.svg" alt="image" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 ms-auto">
            <div className="home-right">
              <div className="counter-box">
                <h4>Presale Ends In</h4>
                <Timer />
              </div>
              <div className="price-text">Price: $0.00155</div>
              <div className="presale-stage">
                <h4>Presale Stage 8</h4>
                <div className="presale-stage-in">
                  {/* <p>Until next price $0.12 (+10%)</p> */}
                  {/* <div className="presale-stage-bar"> */}
                  {/* <span style={{ width: "40%" }}></span> */}
                  {/* <div className="bullet" style={{ width: "40%" }}></div> */}
                  {/* <Form.Label>Range</Form.Label>
                  <Form.Range /> */}
                  {/* </div> */}
                  <Slider
                    aria-label="Volume"
                    // value={value}
                    // onChange={handleChange}
                    color="success"
                    min={1}
                    max={totalCoin}
                  />
                  <p>
                    {value} / {totalCoin}
                  </p>
                </div>
              </div>
              <div class="counter-box-bottom">
                <h4>{70000 - totalCoin} Sold</h4>
                <div class="counter-box-btns">
                  <div class="row">
                    <div class="col-md-6">
                      <a
                        onClick={handleOpenInfo}
                        className="btn btn-border w-100"
                      >
                        How to buy
                      </a>
                    </div>
                    {isLoggedIn && (
                      <div class="col-md-6">
                        <a
                          onClick={() => setOpen(true)}
                          class="btn btn-green w-100"
                        >
                          Buy
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BuyModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        buyToken={buyToken}
        value={value}
        setValue={setValue}
        // handleChangeToggle={handleChangeToggle}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        tokenValue={tokenValue}
        setTokenValue={setTokenValue}
      />
      <HowToBuyModal
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        handleOpenInfo={handleOpenInfo}
        buyToken={buyToken}
      />
    </section>
  );
};

export default Index;
