import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Home from "../../components/home";
import About from "../../components/about";
import Roadmap from "../../components/roadmap";
import Coinomics from "../../components/coinomics";
import OurTeam from "../../components/our-team";
import FAQ from "../../components/faq";
import ContactUs from "../../components/contact-us";
import { GET } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";

const Index = () => {
  const [FAQData, setFAQData] = useState([]);

  useEffect(() => {
    (async () => {
      const faqs = await GET(apis.GET_FAQ_APP);
      console.log({ faqs });
      if (faqs.status) {
        setFAQData(faqs.data.data);
      }
    })();
  }, []);

  return (
    <>
      <Home />
      <About />
      <Roadmap />
      <Coinomics />
      <OurTeam />
      <FAQ FAQData={FAQData} setFAQData={setFAQData} />
      <ContactUs />
    </>
  );
};

export default Index;
