import React from "react";

const Index = () => {
  return (
    <footer>
      <div className="container">
        <div className="row footer-top">
          <div className="col-md-4">
            <figure>
              <img src="images/footer-logo.png" />
            </figure>
          </div>
          <div className="col-md-3">
            <div className="footer-content">
              <h3>Company</h3>
              <ul className="footer-content-list">
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">FAQ'S</a>
                </li>
                <li>
                  <a href="#">Contact us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-content">
              <h3>LEGAL</h3>
              <ul className="footer-content-list">
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
                <li>
                  <a href="#">Cookie policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <div className="footer-content">
              <h3>Socials</h3>
              <ul className="socials-content-list">
                <li>
                  <a href="#">
                    <i className="ri-linkedin-fill"></i>LinkedIn
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="ri-facebook-circle-fill"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i>
                      <img src="images/tweeter-icon.svg" />
                    </i>
                    (Twitter)
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="ri-instagram-fill"></i>Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer text-center">
        <div className="container">
          <p>© 2023 eco2in. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Index;
