// export const BASE_URL = "http://localhost:5000/api/v3/";
export const BASE_URL = "https://hybridstablecoinapi.devtechnosys.tech/api/v3/";

export const apis = {
  LOGIN: "user/login",
  FORGET: "user/forget",
  ACCOUNT: "account",
  ROLE: "role",
  CREATE_USER: "user/create",
  CREATE_LAWYER: "lawyer/create",
  CREATE_PARALEGAL_EXECUTIVE: "paralegal-executive/create",
  GET_PARALEGAL_EXECUTIVE: "paralegal-executive",
  GET_USERS: "user",
  GET_LAWYER: "lawyer",
  CREATE_DEPARTMENT: "department",
  GET_DEPARTMENTS: "department",
  CREATE_SPECIALIZATION: "specialization",
  GET_SPECIALIZATION: "specialization",
  CREATE_BLOG_CATEGORY: "blog/category",
  GET_BLOG_CATEGORY: "blog/category",
  GET_BLOG_CATEGORY_LIST: "blog/category/list",
  GET_BLOG: "blog",
  CREATE_BLOG: "blog",
  CREATE_CASE_CATEGORY: "case/category",
  GET_CASE_CATEGORY: "case/category",
  CREATE_CONTACT_CATEGORY: "contact/category/create",
  GET_CONTACT_CATEGORY: "contact/category/list",
  MODULE: "module",
  PERMISSION: "permission",
  GET_ROLE_BY_ID: "role/role-by-id",
  UPDATE_ROLE_AND_PERMISSION: "permission/role-permission",
  GET_USERS_LATEST_USER: "dashboard/latest-user",
  GET_USERS_COUNT: "dashboard/user-count",
  GET_ME: "user/get-me",
  DASHBOARD_USER_GRAPH: "dashboard/user-graph",
  SUB_ADMIN: "sub-admin",
  FAQ_CREATE: "faq/create",
  FAQ_GET: "faq",
  FAQ_CATEGORY_CREATE: "faq/category/create",
  FAQ_CATEGORY_GET: "faq/category",
  CREATE_TODO: "todo/create",
  GET_TODO: "todo",
  CREATE_JURISDICTION: "jurisdiction/create",
  GET_JURISDICTION: "jurisdiction",
  CREATE_SUBSCRIPTION: "subscription/create",
  GET_SUBSCRIPTION: "subscription",
  CREATE_DOCUMENT_CATEGORY: "document/category/create",
  GET_DOCUMENT_CATEGORY: "document/category",
  GET_DOCUMENT: "document",
  CREATE_DOCUMENT: "document/create",
  CREATE_CASE: "case/create",
  CREATE_CASE_STATUS: "case/status",
  GET_TEMPLATE: "template",
  CREATE_TEMPLATE: "template/create",
  GET_SERVICE: "service",
  CREATE_SERVICE: "service/create",
  VERIFY_OTP: "user/verify-otp",
  NEW_PASSWORD: "user/new-password",

  GET_FAQ_APP: "app/faq",
  CREATE_CONTACT_APP: "app/contact-us",
  CREATE_APP_USER: "app/user/register",
  GET_APP_USER: "app/user/get-me",
  UPDATE_APP_USER: "app/user/update-me",
  LOGIN_APP_USER: "app/user/login",
  FORGOT_PASSWORD_APP_USER: "app/user/forget-password",
  VERIFY_OTP_APP_USER: "app/user/verify-otp",
  RESET_PASSWORD_APP_USER: "app/user/reset-password",
};
