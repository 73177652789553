import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import { init } from "../../store/reducers/ethereumSlice";
import { toast } from "react-toastify";

function EthProvider({ children }) {
  const dispatch = useDispatch();
  const ethereumState = useSelector((state) => state.ethereum);

  const initEthereum = useCallback(
    async (artifact) => {
      if (artifact && window.ethereum) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        const networkID = await web3.eth.net.getId();
        const { abi } = artifact;
        let address, contract;
        try {
          address = artifact.contractAddress;
          contract = new web3.eth.Contract(abi, address);
        } catch (err) {
          console.error(err);
        }
        dispatch(init({ artifact, web3, accounts, networkID, contract }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (window.ethereum) {
      const tryInit = async () => {
        try {
          const artifact = require("../../config.json");
          console.log(window.ethereum);
          initEthereum(artifact);
        } catch (err) {
          console.error(err);
        }
      };

      tryInit();
    }
  }, [initEthereum]);

  useEffect(() => {
    if (window.ethereum) {
      const events = ["chainChanged", "accountsChanged"];
      const handleChange = () => {
        initEthereum(ethereumState.artifact);
      };

      events.forEach((e) => window.ethereum.on(e, handleChange));
      return () => {
        events.forEach((e) => window.ethereum.removeListener(e, handleChange));
      };
    } else {
      toast.warn("Please install metamask to buy token.");
    }
  }, [initEthereum, ethereumState.artifact]);

  return <>{children}</>;
}

export default EthProvider;
