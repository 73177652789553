import { Navigate, createBrowserRouter } from "react-router-dom";
import Login from "./pages/login";
import SignUp from "./pages/signup";
import Home from "./pages/home";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import Verification from "./pages/verification";
import Profile from "./pages/profile";
import PageLayout from "./components/auth-layout";
import MainLayout from "./components/layout";

const router = (isLoggedIn) => {
  return createBrowserRouter([
    {
      path: "/",
      element: (
        <MainLayout>
          <Home />
        </MainLayout>
      ),
    },
    {
      path: "/sign-up",
      element: isLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <PageLayout>
          <SignUp />
        </PageLayout>
      ),
    },
    {
      path: "/forgot-password",
      element: isLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <PageLayout>
          <ForgotPassword />
        </PageLayout>
      ),
    },
    {
      path: "/reset-password",
      element: isLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <PageLayout>
          <ResetPassword />
        </PageLayout>
      ),
    },
    {
      path: "/verification",
      element: isLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <PageLayout>
          <Verification />
        </PageLayout>
      ),
    },
    {
      path: "/login",
      element: isLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <PageLayout>
          <Login />
        </PageLayout>
      ),
    },
    {
      path: "/profile",
      element: (
        <MainLayout>
          <Profile />
        </MainLayout>
      ),
    },
  ]);
};

export default router;
