import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import { useDispatch } from "react-redux";
import { login } from "../../store/reducers/userSlice";
import { toast } from "react-toastify";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        console.log(values);
        values.userPassword = values.password;
        // delete values.password;
        const { data } = await POST(apis.LOGIN_APP_USER, null, values, 0);

        if (data.status) {
          // toast.success(data.message);
          const { token, ...rest } = data.data;
          dispatch(login({ token, user: rest }));
        } else {
          toast.success(data.message);
          // router.push("/user/list");
        }
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message || err.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  // const handleBackButton = () => {}

  return (
    <>
      <div className="col-md-6 form-left-box">
        <button
          className="back-to-home"
          type="button"
          onClick={() => {
            navigate("/");
          }}
        >
          <i class="ri-close-line"></i>
        </button>
        <div className="form-box">
          <div className="form-head">
            <h2>Login</h2>
          </div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                id="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                className="form-control"
                placeholder="Enter your email address"
              />
              {formik.touched.email && formik.errors.email && (
                <p style={{ color: "red" }}>{formik.errors.email}</p>
              )}
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                label="Confirm Password"
                name="password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                className="form-control"
                placeholder="Enter your Password"
              />
              {formik.touched.password && formik.errors.password && (
                <p style={{ color: "red" }}>{formik.errors.password}</p>
              )}
            </div>
            <div className="remember-forgot-box">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  label="rememberMe"
                  name="rememberMe"
                  onChange={formik.handleChange}
                  value={formik.values.rememberMe}
                  placeholder="Enter your rememberMe"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Remember me
                </label>
              </div>
              <div className="forgot-password">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
            <div className="form-submit-btn">
              <button className="btn btn-green">Login</button>
            </div>
            <div className="form-foot-box">
              <p>
                Don’t have an account? <Link to="/sign-up">Register</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-6 form-right-box">
        <div className="form-des">
          <div className="form-logo">
            {/* <a href="index.html"> */}
            <img src="/images/form-logo.png" alt="logo" />
            {/* </a> */}
          </div>
          <h2>Welcome to Stable Coin</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
