import React from "react";
import Header from "../header";
import Footer from "../footer";

const Index = ({ children }) => {
  return (
    <div className="wraper-main">
      <Header />
      <div className="wraper-inner">{children}</div>
      <Footer />
    </div>
  );
};

export default Index;
