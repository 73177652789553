import React from "react";

const Index = () => {
  return (
    <section id="coinomics" className="Coinomics-sec section-space">
      <div className="container">
        <div className="section-head text-center mb-5">
          <h2>Coinomics</h2>
        </div>
        <div className="total-supply-num mb-5">
          <small>Total Supply</small>
          <h3>2,25,000</h3>
        </div>
        <div className="total-supply-ratio text-center">
          <figure>
            <img src="images/ratio-circle.png" alt="img" />
          </figure>
          <h3>Total Supply</h3>
        </div>
        <ul className="total-supply-indicate text-center d-flex align-items-center justify-content-center mt-5">
          <li className="green-tag">Presale</li>
          <li className="purple-tag">Team, Founders & Marketing</li>
          <li className="blue-tag">Uniswap</li>
          <li className="green-tag">BNB</li>
          <li className="orange-tag">Burn</li>
          <li className="red-tag">Airdrop</li>
        </ul>
      </div>
    </section>
  );
};

export default Index;
