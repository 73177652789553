import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import { toast } from "react-toastify";

const Index = () => {
  const navigate = useNavigate();
  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      submit: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(25).required("First name is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum."),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        "Phone number is not valid"
      ),
    }),
    onSubmit: async (values, helpers) => {
      try {
        console.log(values);
        // if (values.confirmPassword === values.password) {

        // }
        const { data } = await POST(apis.CREATE_APP_USER, null, values, 0);
        console.log({ data });
        if (data.status) {
          toast.success(data.message);
          navigate("/login");
        } else {
          toast.error(data.message);
          // router.push("/user/list");
        }
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message || err.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <div className="col-md-6 form-left-box">
        <button
          class="back-to-home"
          type="button"
          onClick={() => {
            navigate("/");
          }}
        >
          <i class="ri-close-line"></i>
        </button>
        <div className="form-box">
          <div className="form-head">
            <h2>Sign up</h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    error={
                      !!(formik.touched.firstName && formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    label="First Name"
                    name="firstName"
                    id="firstName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    className="form-control"
                    placeholder="Enter first name"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <p style={{ color: "red" }}>{formik.errors.firstName}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    error={
                      !!(formik.touched.lastName && formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    label="Last Name"
                    name="lastName"
                    id="lastName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    className="form-control"
                    placeholder="Enter last name"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                id="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                className="form-control"
                placeholder="Enter your email"
              />
              {formik.touched.email && formik.errors.email && (
                <p style={{ color: "red" }}>{formik.errors.email}</p>
              )}
            </div>

            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                error={
                  !!(formik.touched.phoneNumber && formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                label="Phone Number"
                name="phoneNumber"
                id="phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                className="form-control"
                placeholder="Enter your phone number"
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <p style={{ color: "red" }}>{formik.errors.phoneNumber}</p>
              )}
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                name="password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                className="form-control"
                placeholder="Enter your Password"
              />
              {formik.touched.password && formik.errors.password && (
                <p style={{ color: "red" }}>{formik.errors.password}</p>
              )}
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                error={
                  !!(
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  )
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                label="Confirm Password"
                name="confirmPassword"
                id="confirmPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                className="form-control"
                placeholder="Enter your Confirm Password"
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <p style={{ color: "red" }}>
                    {formik.errors.confirmPassword}
                  </p>
                )}
            </div>

            <div className="form-submit-btn">
              <button className="btn btn-green">Submit</button>
            </div>

            <div className="form-foot-box">
              <p>
                Do you already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-6 form-right-box">
        <div className="form-des">
          <div className="form-logo">
            {/* <a href="index.html"> */}
            <img src="/images/form-logo.png" alt="logo" />
            {/* </a> */}
          </div>
          <h2>Welcome to Stable Coin</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
