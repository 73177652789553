import user from "./userSlice";
import ethereum from "./ethereumSlice";
import { combineReducers } from "@reduxjs/toolkit";

const reducers = combineReducers({
  user,
  ethereum,
});

export default reducers;
