import React from "react";
const Index = () => {
  return (
    <section id="about-us" className="about-us section-space">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <figure>
              <img src="images/about-img.png" alt="img" />
            </figure>
          </div>
          <div className="col-md-6">
            <div className="section-head">
              <small>ABOUT US</small>
              <h2>It is a long established fact that a reader will be</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
              <a className="btn btn-green mt-4">Read More</a>
            </div>
          </div>
        </div>
        <div className="objectivs-boxes mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="boxes-head mb-4">
                <figure>
                  <img src="images/objective-purpose.png" alt="img" />
                </figure>
                <h3>Objectives and Purpose</h3>
              </div>
              <hr className="seprator" />
              <ul className="point-list">
                <li>
                  <h3>1. Identify Goals</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since{" "}
                  </p>
                </li>
                <li>
                  <h3>2. Target Audience</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since{" "}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="boxes-head mb-4">
                <figure>
                  <img src="images/research-analysis.png" alt="img" />
                </figure>
                <h3>Research and Analysis</h3>
              </div>
              <hr className="seprator" />
              <ul className="point-list">
                <li>
                  <h3>1. Market Research</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since{" "}
                  </p>
                </li>
                <li>
                  <h3>2. Technical Feasibility</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
