import React, { useEffect, useState } from "react";
import { POST } from "../../apiHelper/methods";
import { apis } from "../../apiHelper/apiConstants";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const Index = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState();

  useEffect(() => {
    setEmail(searchParams.get("email"));
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      submit: null,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum."),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),

    onSubmit: async (values, helpers) => {
      try {
        // console.log(values);
        // if (values.confirmPassword === values.password) {

        // }

        const { data } = await POST(
          apis.RESET_PASSWORD_APP_USER,
          null,
          { ...values, email },
          0
        );
        if (data.status) {
          toast.success(data.message);
          navigate("/login");
        } else {
          toast.error(data.message);
          // router.push("/user/list");
        }
      } catch (err) {
        console.log(err);
        // toast.error(error.response.data.message || error.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <div className="col-md-6 form-left-box">
        <div className="form-box">
          <div className="form-head">
            <h2>Reset password</h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                name="password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                className="form-control"
                placeholder="Enter your Password"
              />
              {formik.touched.password && formik.errors.password && (
                <p style={{ color: "red" }}>{formik.errors.password}</p>
              )}
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                error={
                  !!(
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  )
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                label="Confirm Password"
                name="confirmPassword"
                id="confirmPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                className="form-control"
                placeholder="Enter your Confirm Password"
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <p style={{ color: "red" }}>
                    {formik.errors.confirmPassword}
                  </p>
                )}
            </div>

            <div className="form-submit-btn">
              <button className="btn btn-green">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-6 form-right-box">
        <div className="form-des">
          <div className="form-logo">
            <a href="index.html">
              <img src="/images/form-logo.png" alt="logo" />
            </a>
          </div>
          <h2>Welcome to Stable Coin</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book.
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
