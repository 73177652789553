import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token:
    typeof window !== "undefined" && localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null,
  user:
    typeof window !== "undefined" && localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      typeof window !== "undefined" &&
        localStorage.setItem("token", JSON.stringify(action.payload.token));
      typeof window !== "undefined" &&
        localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
    getMe: (state, action) => {
      state.user = action.payload.user;
      typeof window !== "undefined" &&
        localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
    updateUserPermissions: (state, action) => {
      const user =
        typeof window !== "undefined" && localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : null;
      if (user) {
        user.permission = action.payload.permission;
        state.user = user;
        localStorage.setItem("user", JSON.stringify(user));
      }
    },
    logout: (state, action) => {
      state.token = null;
      state.user = null;
      typeof window !== "undefined" && localStorage.setItem("token", null);
      typeof window !== "undefined" && localStorage.setItem("user", null);
    },
  },
});

export const { login, logout, getMe, updateUserPermissions } =
  userSlice.actions;
export default userSlice.reducer;
