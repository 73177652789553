// import * as React from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import Radio from "@mui/material/Radio";
// import {
//   FormControl,
//   FormControlLabel,
//   FormLabel,
//   RadioGroup,
// } from "@mui/material";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "balck !important    ",
//   //   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// export default function BuyModal({
//   open,
//   setOpen,
//   value,
//   buyToken,
//   setValue,
//   handleChangeToggle,
//   selectedValue,
// }) {
//   //   const [open, setOpen] = React.useState(false);
//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       {/* <Button onClick={handleOpen}>Open modal</Button> */}
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div className="model-head">
//             <h2>Enter number of token you want to buy.</h2>
//             {/* <p>
//               Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
//             </p> */}
//           </div>
//           <div>
//             {/* <Radio
//               checked={selectedValue === "fiat"}
//               onChange={handleChangeToggle}
//               value="fiat"
//               name="radio-buttons"
//               inputProps={{ "aria-label": "A" }}
//               color="success"
//             />
//             <Radio
//               checked={selectedValue === "crypto"}
//               onChange={handleChangeToggle}
//               value="crypto"
//               name="radio-buttons"
//               inputProps={{ "aria-label": "A" }}
//               color="success"
//             /> */}
//             <FormControl>
//               <p sx={{ color: "white" }}>Payment Method</p>
//               <RadioGroup
//                 aria-labelledby="demo-radio-buttons-group-label"
//                 defaultValue="crypto"
//                 name="radio-buttons-group"
//                 onChange={handleChangeToggle}
//                 vlaue={selectedValue}
//               >
//                 <FormControlLabel
//                   value="crypto"
//                   control={<Radio color="success" />}
//                   label="Crypto"
//                 />
//                 <FormControlLabel
//                   value="fiat"
//                   control={<Radio color="success" />}
//                   label="Fiat"
//                 />
//               </RadioGroup>
//             </FormControl>
//           </div>

//           <div className="model-form">
//             <div className="form-group">
//               <input
//                 type="text"
//                 className="form-control"
//                 value={value}
//                 onChange={(event) => setValue(event.target.value)}
//               />
//             </div>
//             <div className="form-submit-box">
//               <button
//                 className="btn btn-green"
//                 type="button"
//                 onClick={buyToken}
//               >
//                 Submit
//               </button>
//             </div>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "black !important    ",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BuyModal({
  open,
  setOpen,
  value,
  buyToken,
  setValue,
  tokenValue,
  setTokenValue,
  handleChangeToggle,
  selectedValue,
  setSelectedValue,
}) {
  //   const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              className={`btn ${selectedValue === "eth" && "btn-border"}`}
              onClick={() => setSelectedValue("eth")}
            >
              By with ETH
            </button>
            <button
              disabled
              className={`btn ${selectedValue === "usdt" && "btn-border"}`}
              onClick={() => setSelectedValue("usdt")}
            >
              By with USDT
            </button>
            <button
              disabled
              className={`btn ${selectedValue === "fiat" && "btn-border"}`}
              onClick={() => setSelectedValue("fiat")}
            >
              By with FIAT
            </button>
          </div>
          <div
            style={{
              // display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
              // direction: "column",
              // width: "100%",
              // height: "100px",
              margin: "20px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                direction: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "10px 20px",
                margin: "10px 0px",
                cursor: "pointer",
              }}
              className="btn-border"
            >
              Metamask
              <div
                style={{
                  width: "30px",
                }}
              >
                <img src="images/metamask.png" alt="metamask" />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                direction: "column",
                width: "100%",
                padding: "10px 20px",
                margin: "10px 0px",
                cursor: "pointer",
              }}
              className="btn-border"
            >
              Exodus
              <div
                style={{
                  width: "30px",
                }}
              >
                <img src="images/Exodus.png" alt="metamask" />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                direction: "column",
                width: "100%",
                padding: "10px 20px",
                margin: "10px 0px",
                cursor: "pointer",
              }}
              className="btn-border"
            >
              Trust wallet
              <div
                style={{
                  width: "30px",
                }}
              >
                <img src="images/Trust.png" alt="metamask" />
              </div>
            </div>
          </div>
          <div className="model-form" style={{ marginTop: "20px" }}>
            <div className="form-group input-with-img">
              <input
                type="text"
                className="form-control"
                value={value}
                onChange={(event) => {
                  setValue(event.target.value);
                  setTokenValue(event.target.value * 0.0002);
                }}
              />
              <div className="input-ic">
                <img src="images/logo.svg" alt="token" />
              </div>
            </div>
            <div className="form-group input-with-img">
              <input
                type="text"
                className="form-control input-icon"
                value={tokenValue}
                style={{
                  margin: "10px 0",
                }}
                // onChange={(event) => setValue(event.target.value)}
              />
              <div className="input-ic">
                <img src="images/eth.png" alt="eth" />
              </div>
            </div>
            <div className="form-submit-box">
              <button
                className="btn btn-green"
                type="button"
                onClick={buyToken}
                disabled
              >
                Submit
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
