import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "balck !important    ",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function HowToBuyModal({ openInfo, setOpenInfo }) {
  //   const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpenInfo(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={openInfo}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="model-head">
            <h2>
              To facilitate token buy, the following steps should be followed to
              purchase tokens:
            </h2>
            <h5 style={{ margin: "10px 0px" }}>1.) User require to login.</h5>
            <h5 style={{ margin: "10px 0px" }}>
              2.) Click on the "Buy" button to proceed with the purchase.
            </h5>
            <h5 style={{ margin: "10px 0px" }}>
              3.) Enter the desired amount of tokens.
            </h5>
            <h5 style={{ margin: "10px 0px" }}>
              4.) Initiate the payment process using MetaMask.
            </h5>
            <h5 style={{ margin: "10px 0px" }}>
              5.) Upon successful payment, the tokens will be transferred to the
              user's account.
            </h5>
            <div className="form-submit-box">
              <button
                className="btn btn-green"
                type="button"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
