import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Index = ({ FAQData, setFAQData }) => {
  const [expanded, setExpanded] = useState("panel0");

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  // const [firstFAQ, setFirstFAQ] = useState({});

  // useEffect(() => {
  //   if (FAQData.length > 0) setFirstFAQ({ faq: FAQData[0] });
  // }, [FAQData]);

  // console.log({ firstFAQ });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section id="faq" className="faq-sec section-space">
      <div className="container">
        <div className="section-head text-center mb-5">
          <h2>Frequently asked questions</h2>
        </div>
        {FAQData.length > 0 &&
          FAQData.map((_faq, index) => (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: expanded !== `panel${index}` ? "white" : "#abca2f",
                      fontSize: "24px",
                    }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    flexShrink: 0,
                    color: expanded !== `panel${index}` ? "white" : "#abca2f",
                    fontSize: "24px",
                  }}
                >
                  {_faq.question}
                </Typography>
                {/* <Typography sx={{ color: "text.secondary" }}>
                  I am an accordion
                </Typography> */}
              </AccordionSummary>
              <AccordionDetails sx={{ color: "#bfc1b4", fontSize: "18px" }}>
                <Typography>{_faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </section>
  );
};

export default Index;
//  {firstFAQ && firstFAQ.faq && firstFAQ.faq.question && (
//           <div className="faq-inner">
//             <div class="accordion-item">
//               <h2 class="accordion-header" id={`headingfirst`}>
//                 <button
//                   class="accordion-button"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target={`#collapsefirst`}
//                   aria-expanded={firstFAQ.expand || true}
//                   aria-controls={`collapsefirst`}
//                   onClick={handleButtonClick}
//                 >
//                   {firstFAQ.faq.question}
//                 </button>
//               </h2>
//               <div
//                 id={`collapsefirst`}
//                 class="accordion-collapse collapse show"
//                 aria-labelledby={`headingfirst`}
//                 data-bs-parent="#accordionExample"
//               >
//                 <div class="accordion-body">
//                   <p>{firstFAQ.faq.answer}</p>
//                 </div>
//               </div>
//             </div>
//             {FAQData.length > 0 &&
//               FAQData.map((_faq, index) => {
//                 return (
//                   <>
//                     {index !== 0 && (
//                       <div class="accordion-item" key={index}>
//                         <h2 class="accordion-header" id={`heading${index}`}>
//                           <button
//                             class="accordion-button collapsed"
//                             type="button"
//                             data-bs-toggle="collapse"
//                             data-bs-target={`#collapse${index}`}
//                             aria-expanded={_faq.expand || false}
//                             aria-controls={`collapse${index}`}
//                             onClick={() => handleButtonClick(index)}
//                           >
//                             {_faq.question}
//                           </button>
//                         </h2>
//                         <div
//                           id={`collapse${index}`}
//                           class="accordion-collapse collapse"
//                           aria-labelledby={`heading${index}`}
//                           data-bs-parent="#accordionExample"
//                         >
//                           <div class="accordion-body">
//                             <p>{_faq.answer}</p>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </>
//                 );
//               })}
//           </div>
//         )}
