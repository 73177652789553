import React, { useEffect, useState } from "react";

const targetTime = new Date("2024-02-28").getTime();
const Index = () => {
  const [currentTime, setCurrentTime] = useState(Date.now());

  const timeBetween = targetTime - currentTime;
  const seconds = Math.floor((timeBetween / 1000) % 60);
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

  function numberFormat(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="countdown">
      <ul>
        <li>
          <div className="countdown-in">
            <span id="days">{numberFormat(days)}</span>days
          </div>
        </li>
        <li>
          <div className="countdown-in">
            <span id="hours">{numberFormat(hours)}</span>
            Hours
          </div>
        </li>
        <li>
          <div className="countdown-in">
            <span id="minutes">{numberFormat(minutes)}</span>
            Minutes
          </div>
        </li>
        <li>
          <div className="countdown-in">
            <span id="seconds">{numberFormat(seconds)}</span>
            Seconds
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Index;
