import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../store/reducers/userSlice";
import BuyModal from "../modal";
import { toast } from "react-toastify";
import Loader from "../loader/index";

const Index = () => {
  const { token } = useSelector((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [scrollBGClass, setScrollBGClass] = useState("");
  const [value, setValue] = useState(1);
  const [loaderState, setLoaderState] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { accounts, contract, web3 } = useSelector((state) => state.ethereum);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload(false);
    // navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 1) {
        setScrollBGClass("sticky");
      }
    });
  }, []);

  const buyToken = async () => {
    if (window.ethereum) {
      try {
        setLoaderState(true);
        const totalPrice = 1 * value;
        const valueInWei = web3.utils.toWei(totalPrice, "ether");
        const data = await contract?.methods
          ?.sendToken(value)
          .send({ from: accounts[0], value: valueInWei });
        const balance = await contract?.methods
          ?.balanceOf("0x1082A38E653957dF8D89aF415651ABE29492c001")
          ?.call();
        if (balance) {
          // eslint-disable-next-line no-undef
          setTotalCoin(BigInt(balance) / BigInt(1000000000000000000) + "");
        }
        if (data) {
          toast.success("Token transferred successfully.");
          setLoaderState(false);
          setOpen(false);
        }
      } catch (error) {
        setLoaderState(false);
        toast.error(error.message);
      }
    } else {
      setLoaderState(false);
      toast.warn("Please install metamask to buy token.");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {loaderState && <Loader />}
      <header className={`topHeader ${scrollBGClass}`} id="fixed-header">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link className="navbar-brand" href="#">
              <img src="images/logo.svg" alt="img" />
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon">
                <i class="ri-menu-line"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about-us">
                    About us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#coinomics">
                    Whitepaper
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq">
                    How to Buy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    Contact us
                  </a>
                </li>
              </ul>
              {!isLoggedIn && (
                <div className="header-btns ps-3">
                  <Link to="/login" className="btn btn-border">
                    Login
                  </Link>
                  <Link to="/sign-up" className="btn btn-green">
                    Signup
                  </Link>
                </div>
              )}
              {/* {isLoggedIn && (
                <div class="profile-drop-box ps-3">
                  <div class="dropdown profile-dropdown">
                    <button
                      class="btn  dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <figure>
                        <img src="assets/images/user-img.png" />
                      </figure>
                      <h4>
                        {user && user.firstName} {user.lastName}
                      </h4>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/profile">
                          <i class="ri-user-fill"></i> My profile
                        </Link>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <i class="ri-lock-fill"></i>Change password
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <i class="ri-logout-box-line"></i>Log out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )} */}
              {isLoggedIn && (
                <div className="header-btns ps-3">
                  <button
                    onClick={() => setOpen(true)}
                    // to="/login"
                    className="btn btn-border"
                    type="submit"
                  >
                    Buy
                  </button>
                </div>
              )}
              {isLoggedIn && (
                <div className="header-btns ps-3">
                  <button
                    onClick={logoutHandler}
                    to="/login"
                    className="btn btn-border"
                    type="submit"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
      <BuyModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        buyToken={buyToken}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default Index;
