import React from "react";
import { Outlet } from "react-router-dom";

const Index = ({ children }) => {
  return (
    <div class="form-wraper">
      <div class="row">
        {/* <Outlet /> */}
        {children}
      </div>
    </div>
  );
};

export default Index;
